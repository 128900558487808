import React from "react"
import BackgroundImage from "./components/background-image"
import { SectionType } from "../../types"
import Form from "../form"
import { createMarkup } from "../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const FormSection = ({ section, index, ...props }: SectionProps) => {
  
  return (
    <div className={`relative ${section.advanced?.class || ``}`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-2 md:mx-auto">
        {section.blurb && <div className="mb-5" dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
        <Form form={section.form} {...props} />
      </div>
    </div>
  )
}

export default FormSection
